import React, {useMemo, useState} from 'react';
import AppRouter from "./AppRouter";
import {AuthContext} from "./context/AuthContext";
import {SnackBarContext} from "./features/snackbar/context/SnackBarContext";
import {SnackBarType} from "./features/snackbar/models/SnackBarType";
import SnackBarMessage from "./features/snackbar/components/SnackBarMessage";
import {SESSION_STORAGE_LOGGED} from "./utils/Constant";
import 'mapbox-gl/dist/mapbox-gl.css';
function App() {
    const [logged, setLogged] =
        useState<boolean>(sessionStorage.getItem(SESSION_STORAGE_LOGGED) === "true");
    const authValue = useMemo(() =>
        ({logged, setLogged}),
        [logged])
    const [open, setOpen] = useState<boolean>(false);
    const [snackBarType, setSnackBarType] = useState<SnackBarType>("success");
    const [message, setMessage] = useState<string>("success");
    const snackBarValue = useMemo(() =>
            ({open, setOpen, snackBarType, setSnackBarType, message, setMessage}),
        [snackBarType])

  return (
    <AuthContext.Provider value={authValue}>
        <SnackBarContext.Provider value={snackBarValue}>
            <AppRouter />
            <SnackBarMessage open={open}
                             setOpen={setOpen}
                             snackBarType={snackBarType}
                             message={message}/>
        </SnackBarContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
