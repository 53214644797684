import styles from "./TableFormComponent.module.scss";
import {
    Button, Checkbox,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import {ChangeEvent, Dispatch, FunctionComponent, SetStateAction, useEffect, useState} from "react";
import Add from "@mui/icons-material/Add"
import Edit from "@mui/icons-material/Edit"
import {PaginatedResponse} from "../../features/management/models/PaginatedResponse";
import SearchBar from "../SearchBar/SearchBar";
import TableRowRender from "./TableRowRender";
import TablePaginationCustom from "./TablePaginationCustom";
import TableHeaderSort, {ITableColumn} from "./TableHeaderSort";
import FormRender, {IFormDataProps} from "./FormRender";
import DeleteIcon from '@mui/icons-material/Delete';
import TabCheckbox from "./TabCheckbox";

export default function TableFormComponent<T extends Object>({
                                                                 title,
                                                                 data,
                                                                 buttonText,
                                                                 tableColumn,
                                                                 FormComponent,
                                                                 onShowFormChange,
                                                                 handlePageChange,
                                                                 handleRowChange,
                                                                 orderTable,
                                                                 page,
                                                                 rowsPerPage,
                                                                 order,
                                                                 setFts,
                                                                 selected = [],
                                                                 setSelected,
                                                                 onDeleteClick
                                                             }: TableComponentProps<T>) {
    const [showForm, setShowForm] = useState<boolean>(false);
    const [item, setItem] = useState<T>();

    const handleRowClick = (item: T) => {
        setItem(item);
        setShowForm(true);
    }

    // Call onShowFormChange whenever showForm state changes
    useEffect(() => {
        onShowFormChange(!showForm)
        setItem(undefined);
    }, [showForm]);

    return (
        <div className={styles.main_container}>
            {showForm ?
                <FormRender data={item}
                            FormComponent={FormComponent}
                            setShowForm={setShowForm}/> :
                <TableRender title={title}
                             setShowForm={setShowForm}
                             data={data}
                             tableColumn={tableColumn}
                             handleRowClick={handleRowClick}
                             handlePageChange={handlePageChange}
                             handleRowChange={handleRowChange}
                             orderTable={orderTable}
                             page={page}
                             rowsPerPage={rowsPerPage}
                             order={order}
                             setFts={setFts}
                             selected={selected}
                             setSelected={setSelected}
                             onDeleteClick={onDeleteClick}
                />}
        </div>
    )
}

export function TableRender<T extends Object>({
                                           title,
                                           data,
                                           tableColumn,
                                           setShowForm,
                                           handleRowClick,
                                           handlePageChange,
                                           handleRowChange,
                                           page,
                                           rowsPerPage,
                                           orderTable,
                                           order,
                                           searchable = true,
                                           editable = true,
                                           checkable = false,
                                           deletable = false,
                                           setFts,
                                           selected = [],
                                           setSelected,
                                           onDeleteClick
                                       }: TableRenderProps<T>) {

    const handleButton = () => {
        if (setShowForm) {
            setShowForm(true);
        }
    }

    const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
        if (setSelected) {
            if (event?.target?.checked) {
                const newSelected = data?.data.map((value) => value);
                setSelected(newSelected);
            } else {
                setSelected([]);
            }
        }
    }

    const handleSelect = (checked: boolean, data: T) => {
        if (setSelected) {
            if (checked) {
                setSelected(prevState => [...prevState, data]);
            } else {
                setSelected(prevState => {
                    return  prevState.filter((value) => value != data);
                });
            }
        }
    }

    return (
        <div style={{overflow: "hidden"}}>
            <div className={styles.top_container}>
                <label className={styles.title}>{title}</label>
                {setShowForm && <Button variant={"contained"}
                         style={{borderRadius: '150px'}}
                         onClick={handleButton}
                ><Add/></Button>}
            </div>
            <TableContainer component={Paper}>
                <div className={styles.table_top_container}>
                    {searchable && <SearchBar onSearch={setFts}/>}
                    {deletable && selected?.length > 0 &&
                        <IconButton color={"error"} onClick={onDeleteClick}>
                            <DeleteIcon sx={{width: 40, height: 40}} />
                        </IconButton>
                    }
                </div>
                <Table sx={{minWidth: 650}}
                       size={"small"}>
                    <TableHead>
                        <TableRow>
                            {checkable &&
                                <TableCell padding={"checkbox"} key={"checked"} align="left">
                                    <Checkbox
                                        color="primary"
                                        checked={selected?.length > 0}
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                            }
                            {tableColumn.map((column) =>
                            <TableHeaderSort column={column}
                                             order={order}
                                             key={column.headerTitle}
                                             orderTable={orderTable}/>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.data?.map((data, index) => (
                            <TableRow key={index}>
                                {checkable &&
                                   <TabCheckbox key={index + "checked"}
                                                data={data}
                                                onChecked={handleSelect}
                                                selected={selected}/>
                                }
                                {tableColumn.map((row, i) =>
                                    <TableRowRender data={row.data(data)}
                                                    // @ts-ignore
                                                    onClick={() => row.onClick(data)}
                                                    type={row.type}
                                                    key={index + i} />)}
                                {editable &&
                                    <TableCell key={index + "edit"} align="left">
                                        <IconButton onClick={() => handleRowClick ? handleRowClick(data) : null}>
                                            <Edit/>
                                        </IconButton>
                                    </TableCell>
                                }
                            </TableRow>
                        ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePaginationCustom data={data}
                                   page={page}
                                   rowsPerPage={rowsPerPage}
                                   handlePageChange={handlePageChange}
                                   handleRowChange={handleRowChange}/>
        </div>
    )
}


interface TableComponentProps<T> {
    title: string;
    buttonText: string;
    data: PaginatedResponse<T>;
    tableColumn: ITableColumn<T>[];
    FormComponent: FunctionComponent<IFormDataProps<T>>;
    onShowFormChange: (showForm: boolean) => void;
    page: number;
    handlePageChange: (event: unknown, page: number) => void;
    rowsPerPage: number;
    handleRowChange: Dispatch<SetStateAction<number>>;
    order: string;
    orderTable: (value: string) => void;
    setFts: (value: string) => void;
    selected?: T[];
    setSelected?: Dispatch<SetStateAction<T[]>>;
    onDeleteClick?: () => void;
}

interface TableRenderProps<T> {
    title: string;
    data: PaginatedResponse<T>;
    tableColumn: ITableColumn<T>[];
    editable?: boolean;
    searchable?: boolean;
    setShowForm?: Dispatch<SetStateAction<boolean>>;
    handleRowClick?: (item: T) => void;
    page: number;
    handlePageChange: (event: unknown, page: number) => void;
    rowsPerPage: number;
    handleRowChange: Dispatch<SetStateAction<number>>;
    orderTable: (value:string) => void;
    order: string;
    setFts: (value: string) => void;
    checkable?: boolean;
    deletable?: boolean;
    selected?: T[];
    setSelected?: Dispatch<SetStateAction<T[]>>;
    onDeleteClick?: () => void;
}
