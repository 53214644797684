import {useContext, useEffect, useState} from "react";
import {SnackBarContext} from "../features/snackbar/context/SnackBarContext";
import i18n from "../i18n";
import {getAllProject} from "../api/project";
import {AxiosResponse} from "axios";
import TableFormComponent from "../components/table/TableFormComponent";
import {ProjectForm} from "../features/management/components/ProjectForm";
import {Project} from "../features/management/models/Project";
import {PaginatedResponse} from "../features/management/models/PaginatedResponse";
import {ITableColumn} from "../components/table/TableHeaderSort";
import {useNavigate} from "react-router-dom";
import {PROJECT_DETAIL_PAGE} from "../utils/Constant";
import {handleErrorMessage} from "../utils/Common";

export default function ProjectManagement() {
    const {setSnackBarType, setOpen, setMessage} = useContext(SnackBarContext);
    const [data, setData] = useState<PaginatedResponse<Project>>(new PaginatedResponse<Project>());
    const [canUpdate, setCanUpdate] = useState<boolean>(true);
    const [fts, setFts] = useState<string>("");
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [sorting,setSorting] = useState<string>("");
    const navigate = useNavigate();

    const tableColumn: ITableColumn<Project>[] = [
        {
            headerTitle: i18n.t("header.project.name"),
            sortName: "name",
            data: (project: Project) => project.name,
            type: "clickable",
            onClick: (project: Project) => {
                navigate(PROJECT_DETAIL_PAGE, {state: {project: project}});
            }
        },
        {
            headerTitle: i18n.t("header.project.address"),
            sortName: "adress",
            data: (project: Project) => project.adress,
            type: "string"
        },
        {
            headerTitle: i18n.t("header.project.start_activity"),
            sortName: "activeWindowStart",
            data: (project: Project) => project.activeWindowStart,
            type: "date"
        },
        {
            headerTitle: i18n.t("header.project.end_activity"),
            sortName: "activeWindowEnd",
            data: (project: Project) => project.activeWindowEnd,
            type: "date"
        },
    ];

    const getAllProjects = (fts:string,page:number,row:number) => {
        getAllProject(fts,page,row,sorting).then((response: AxiosResponse<PaginatedResponse<Project>>) => {
            setData(response.data);
        }).catch((error) => {
            setSnackBarType("error")
            setMessage(handleErrorMessage(error));
            setOpen(true);
        })
    }
    const handlePageChange = (event: unknown, page: number) =>  {
        setPage(page);
    }

    useEffect(() => {
        if (canUpdate) {
            getAllProjects(fts,page*rowsPerPage,rowsPerPage);
        }
    }, [canUpdate,fts,page,rowsPerPage,sorting])

    return(
        <div style={{width:'100%'}}>
            <TableFormComponent title={i18n.t("project.title")}
                                buttonText={i18n.t("project.create")}
                                FormComponent={ProjectForm}
                                tableColumn={tableColumn}
                                data={data}
                                onShowFormChange={(value: boolean) => setCanUpdate(value)}
                                handlePageChange={handlePageChange}
                                handleRowChange={setRowsPerPage}
                                orderTable={setSorting}
                                setFts={setFts}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                order={sorting}
            />
        </div>
    )
}


