import {Project} from "./Project";

export class Client {
    id: number;
    username: string;
    email: string;
    password: string;
    activeWindowStart: Date;
    activeWindowEnd: Date;
    active: boolean;
    created_at: Date;
    projects: Project[];

}
