const transformErrorMessage = (value:any) =>{

    if(value!== undefined && value !== null){
        return JSON.stringify(value).replace(/[\[\]{}"':]/g, ' ');
    }
}

const handleErrorMessage = (error : any) => {
    if (error?.response?.data) {
        const message = error.response.data.message;
        const details = transformErrorMessage(error.response.data.details);

        if (message && details) {
            return message + ' ' + details;
        } else if (message) {
            return message;
        }
    }

    return error.message;
}

export { handleErrorMessage }