import {useContext, useEffect, useState} from "react";
import {AxiosError, AxiosResponse} from "axios";
import {ErrorResponse} from "../models/ErrorResponse";
import {deleteReport, getReportGroupByProject} from "../api/report";
import {PaginatedResponse} from "../features/management/models/PaginatedResponse";
import {Report} from "../features/management/models/report/Report";
import {SnackBarContext} from "../features/snackbar/context/SnackBarContext";
import TableGroupComponent from "../components/table/group/TableGroupComponent";
import i18n from "../i18n";
import {ITableColumn} from "../components/table/TableHeaderSort";
import ReportModal from "../components/modal/ReportModal";
import {ReportPaginationRequest} from "../features/report/models/ReportPaginationRequest";
import DeleteModal from "../components/modal/DeleteModal";
import {handleErrorMessage} from "../utils/Common";

export default function Reporting() {

    const formatDate = (dateString: string): string => {
        return new Date(dateString).toLocaleDateString('fr-FR', {year:'numeric',month:'2-digit',day:'2-digit'});
    };

    const tableColumn: ITableColumn<Report>[] = [
        {
            headerTitle: i18n.t("header.report.name"),
            sortName: "name",
            data: (report: Report) => report.name,
            type: "string",
        },
        {
            headerTitle: i18n.t("header.report.type"),
            sortName: "username",
            data: (report: Report) => i18n.t("report.type." + report.type),
            type: "string",
            disable: true,
        },
        {
            headerTitle: i18n.t("header.report.status"),
            sortName: "username",
            data: (report: Report) => i18n.t("report.status." + report.status),
            type: "string",
            disable: true,
        },
        {
            headerTitle: i18n.t("header.report.creation_date"),
            sortName: "username",
            data: (report: Report) => formatDate(report.creationDate),
            type: "string",
            disable: true,
        },
        {
            headerTitle: i18n.t("header.report.description"),
            sortName: "description",
            data: (report: Report) => report.description,
            type: "string",
        },
        {
            headerTitle: i18n.t("header.report.image"),
            sortName: "username",
            data: (report: Report) => i18n.t("report.see_img"),
            type: "button",
            onClick: (report: Report) => {handleRowClick(report)},
            disable: true,
        },
    ]

    const [data, setData] = useState<PaginatedResponse<Map<string, Report[]>>>(new PaginatedResponse());
    const {setSnackBarType, setOpen, setMessage} = useContext(SnackBarContext);
    const [fts, setFts] = useState<string>("");
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [sorting,setSorting] = useState<string>("report_id:desc");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [report, setReport] = useState<Report>(new Report());
    const [selected, setSelected] = useState<Report[]>([]);

    useEffect(() => {
        search();
    }, [sorting, fts, rowsPerPage, page])

    const search = () => {
        let request: ReportPaginationRequest = {
            order: sorting,
            fts: fts,
            row: rowsPerPage,
            offset: page * rowsPerPage
        }
        getReportGroupByProject(request).then(
            (r: AxiosResponse<PaginatedResponse<Map<string, Report[]>>>) => {
                setData(r.data);
            }
        ).catch((error: AxiosError<ErrorResponse>) => {
            setSnackBarType("error")
            setMessage(handleErrorMessage(error));
            setOpen(true);
        });
        setSelected([]);
    }
    const handleRowClick = (data: Report) => {
        setReport(data);
        setShowModal(true);
    }
    const handleClose = () => {
        setReport(new Report());
        setShowModal(false)
    }

    const handlePageChange = (event: unknown, page: number) =>  {
        setPage(page);
    }
    const handleDelete = () => {
        selected.forEach((value) => {
            deleteReport(value.id).then(r => {
                search();
            }).catch(error => {
                console.log(error)
            })
        })
        setShowDeleteModal(false);
    }

    return(
        <div style={{width: '100%'}}>
            <TableGroupComponent title={i18n.t("report.project_title")}
                                 data={data}
                                 tableColumn={tableColumn}
                                 page={page}
                                 handlePageChange={handlePageChange}
                                 rowsPerPage={rowsPerPage}
                                 handleRowChange={setRowsPerPage}
                                 orderTable={setSorting}
                                 order={sorting}
                                 deletable={true}
                                 checkable={true}
                                 selected={selected}
                                 onDeleteClick={() => setShowDeleteModal(true)}
                                 setSelected={setSelected}
                                 setFts={setFts}/>

            <ReportModal open={showModal}
                         handleClose={handleClose}
                         report={report}/>
            <DeleteModal open={showDeleteModal}
                         handleDelete={handleDelete}
                         selectedNumber={selected.length}
                         handleClose={() => setShowDeleteModal(false)}/>
        </div>
    )
}


