import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import FR from './locales/fr.json'

const resources = {

    fr: {
        translation: FR
    }
}
i18n.use(initReactI18next).init({
    resources,
    lng: "fr"
});

export default i18n;
