import styles from "./ReportModal.module.scss";
import {Button, MobileStepper, Modal} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {AxiosResponse} from "axios";
import {ReportImages} from "../../features/management/models/report/ReportImages";
import {SnackBarContext} from "../../features/snackbar/context/SnackBarContext";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {Report} from "../../features/management/models/report/Report";
import i18n from "../../i18n";
import {getReportImagesByReportId, rejectReport, validateReport} from "../../api/report";
import ReportIcon from '@mui/icons-material/Report';
import {handleErrorMessage} from "../../utils/Common";

export default function ReportModal({open, handleClose, report}: ReportModalProps) {
    const {setSnackBarType, setOpen, setMessage} = useContext(SnackBarContext);
    const [data, setData] = useState<ReportImages[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const close = () => {
        setCurrentIndex(0);
        setData([]);
        handleClose();
    }

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex + 1 === data.length ? 0 : prevIndex + 1
        );
    };
    const handlePrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex - 1 < 0 ? data.length - 1 : prevIndex - 1
        );
    };

    const onValidateReport = () => {
        validateReport(report.id).then((response: AxiosResponse<Report>) => {
            setSnackBarType("success")
            setMessage(i18n.t("report.validate_success"));
            setOpen(true);
            close();
        }).catch((error) => {
            console.log(error);
            setSnackBarType("error")
            setMessage(handleErrorMessage(error));
            setOpen(true);
        });
    }

    const onRejectReport = ()=>{
        rejectReport(report.id).then((response: AxiosResponse<Report>) => {
            setSnackBarType("success")
            setMessage(i18n.t("report.rejected_success"));
            setOpen(true);
            close();
        }).catch((error) => {
            console.log(error);
            setSnackBarType("error")
            setMessage(handleErrorMessage(error));
            setOpen(true);
        });
    }

    useEffect(() => {
        if (report?.id && report.id !== 0) {
            getReportImagesByReportId(report.id).then((response: AxiosResponse<ReportImages[]>) => {
                setData(response.data);
            }).catch((error) => {
                setSnackBarType("error")
                setMessage(handleErrorMessage(error));
                setOpen(true);
            })
        }
        console.log(report,"report")
    }, [report])

    const formatDate = (dateString: string): string => {
        return new Date(dateString).toLocaleDateString('fr-FR', {year:'numeric',month:'2-digit',day:'2-digit'});
    };

    return (
        <Modal open={open} onClose={close} className={styles.modal}>
            <div className={styles.mainContainer}>
                <div className={styles.topContainer}>
                    <ReportIcon className={styles.icon}/>
                    <div className={styles.topTextContainer}>
                        <div>
                            <span className={styles.textBold}>Report Name:</span>
                            <span className={styles.text}>{report.name}</span>
                        </div>
                        <div>
                            <span className={styles.textBold}>Type:</span>
                            <span className={styles.text}>{i18n.t("report.type." + report.type)}</span>
                        </div>
                        <div>
                            <span className={styles.textBold}>Status:</span>
                            <span className={styles.text}>{i18n.t("report.status." + report.status)}</span>
                        </div>
                        <div>
                            <span className={styles.textBold}>Description:</span>
                            <span className={styles.text}>{report.description}</span>
                        </div>
                        <div>
                            <span className={styles.textBold}>Créer le:</span>
                            <span className={styles.text}>{formatDate(report.creationDate)}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.carouselImages}>
                    <img
                        alt=""
                        className={styles.img}
                        key={currentIndex}
                        src={data[currentIndex]?.webUrl}
                    />
                    {data.length > 1 && (
                        <MobileStepper
                            position="static"
                            activeStep={currentIndex}
                            steps={data?.length}
                            backButton={
                                <Button
                                    size="small"
                                    className={styles.buttonContainer}
                                    onClick={handlePrevious}
                                >
                                    <KeyboardArrowLeft/>
                                    {i18n.t("common.previous")}
                                </Button>
                            }
                            nextButton={
                                <Button
                                    size="small"
                                    className={styles.buttonContainer}
                                    onClick={handleNext}
                                >
                                    {i18n.t("common.next")}
                                    <KeyboardArrowRight/>
                                </Button>
                            }
                        />
                    )}
                    {(report.status === 'PENDING' || report.status === 'REJECTED') && <div><Button onClick={onValidateReport}>Valider le rapport</Button></div>}
                    {(report.status === 'ACCEPTED' ) && <div><Button onClick={onRejectReport}>Rejeter le rapport</Button></div>}

                </div>
            </div>
        </Modal>
    )
}

interface ReportModalProps {
    open: boolean;
    handleClose: () => void;
    report: Report;
}
