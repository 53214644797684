import React, {useRef, useEffect} from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import {MAPBOX_TOKEN} from "../../utils/Constant";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import i18n from "../../i18n";
// TODO maybe use user location ?
const DEFAULT_LONGITUDE = 4.904480125865877;
const DEFAULT_LATITUDE = 50.60912026923175;

export type DrawnShapeCoordinates = {
    bottomLeft: [number, number];
    bottomRight: [number, number];
    topRight: [number, number];
    topLeft: [number, number];
};

interface MapboxMapProps {
    onShapeDrawn: (coordinates: DrawnShapeCoordinates) => void;
    adressUpdate: (value: string) => void;
    data?: DrawnShapeCoordinates;
}

const MapboxMap: React.FC<MapboxMapProps> = ({onShapeDrawn, adressUpdate, data}) => {
    const mapContainer = useRef<HTMLDivElement | null>(null);
    const map = useRef<mapboxgl.Map | null>(null);
    const draw = useRef<MapboxDraw | null>(null);

    useEffect(() => {
        data ? generateMap() : generateInteractiveMap();

        return () => {
            if (map.current) {
                map.current.remove();
            }
        };
    }, []);

    const generateMap = () => {
        if (data) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current!,
                style: 'mapbox://styles/mapbox/satellite-v9',
                center: data.bottomRight ?? [DEFAULT_LONGITUDE, DEFAULT_LATITUDE],
                zoom: 15,
                accessToken: MAPBOX_TOKEN,
                interactive: false
            });

            map.current?.on('load', () => {
                map.current?.addSource('maine', {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        geometry: {
                            type: 'Polygon',
                            coordinates: [
                                [
                                    data.bottomLeft,
                                    data.bottomRight,
                                    data.topRight,
                                    data.topLeft
                                ]
                            ]
                        }
                    }

                })

                // Add a new layer to visualize the polygon.
                map.current?.addLayer({
                    'id': 'maine',
                    'type': 'fill',
                    'source': 'maine', // reference the data source
                    'layout': {},
                    'paint': {
                        'fill-color': '#0080ff', // blue color fill
                        'fill-opacity': 0.5
                    }
                });
            });
        }
    }

    const generateInteractiveMap = () => {
        map.current = new mapboxgl.Map({
            container: mapContainer.current!,
            style: 'mapbox://styles/mapbox/satellite-v9',
            center: [DEFAULT_LONGITUDE, DEFAULT_LATITUDE],
            zoom: 15,
            accessToken: MAPBOX_TOKEN,
        });

        draw.current = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
                trash: true
            }
        });

        if (map.current) {
            map.current?.addControl(draw.current);
            let geocoder = new MapboxGeocoder({
                accessToken: MAPBOX_TOKEN,
                mapboxgl: mapboxgl,
                placeholder: i18n.t("map.search_placeholder"),
                reverseGeocode: true
            });
            map.current?.addControl(geocoder, 'top-left');

            map.current.on('draw.create', (e) => {
                const drawnShape = e.features[0];
                if (drawnShape.geometry.type === 'Polygon') {
                    const coordinates = drawnShape.geometry.coordinates[0];
                    const [bottomLeft, bottomRight, topRight, topLeft] = coordinates;
                    onShapeDrawn({
                        bottomLeft,
                        bottomRight,
                        topRight,
                        topLeft,
                    });

                    // Keep only one polygon
                    // @ts-ignore
                    draw.current?.getAll()?.features?.forEach(d => {
                        if (d.id !== drawnShape.id) {
                            // @ts-ignore
                            draw.current?.delete(d.id);
                        }
                    })

                    let centerLng = (bottomLeft[0] + topRight[0]) / 2;
                    let centerLat = (bottomLeft[1] + topRight[1]) / 2;

                    reverseGeocoding(centerLng, centerLat);
                }
            });

            // Can be use to do something after user search
            // geocoder?.on("result", (e) => {
            // })
        }
    }

    const reverseGeocoding = (longitude: number, latitude: number) => {
        const geocodeApiUrl =
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude}, ${latitude}.json?access_token=${MAPBOX_TOKEN}`;

        fetch(geocodeApiUrl)
            .then((response) => response.json())
            .then(data => {
                if (data?.features?.length > 0) {
                    adressUpdate(data.features[0]?.place_name);
                }
            })
            .catch((error) => {
                console.error('Error geocoding address:', error);
            });
    };


    return (
        <div>
            <link rel="stylesheet"
                  href="https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.7.2/mapbox-gl-geocoder.css"
                  type="text/css"/>
            <div ref={mapContainer} style={{width: '100%', height: '400px'}}/>
        </div>
    );
};

export default MapboxMap;
