// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: rgba(239, 238, 238, 0.71);
}

.SnackBarType_border_shadow__PuxRb {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.SnackBarType_border__FzvS0 {
  border: 1px solid #dacece;
}

.mapboxgl-ctrl-geocoder {
  z-index: 0 !important;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-righ,
.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-right {
  z-index: 0 !important;
}

.SnackBarType_alert_message__0\\+1\\+3 {
  font-size: 2em !important;
}

.css-1vooibu-MuiSvgIcon-root {
  width: 2em !important;
  height: 2em !important;
}

.css-ptiqhd-MuiSvgIcon-root {
  width: 2em !important;
  height: 2em !important;
}

.css-1hdyuqk-MuiPaper-root-MuiAlert-root {
  background-color: rgb(255, 180, 180) !important;
}

.css-wnkddu-MuiPaper-root-MuiAlert-root {
  background-color: rgb(237, 247, 180) !important;
}`, "",{"version":3,"sources":["webpack://./src/styles.module.scss","webpack://./src/features/snackbar/components/SnackBarType.module.scss"],"names":[],"mappings":"AAYA;EACE,2CAXiB;ACAnB;;ADcA;EACE,iDAAA;ACXF;;ADcA;EACE,yBAAA;ACXF;;ADcA;EACE,qBAAA;ACXF;;ADcA;;;;EAIE,qBAAA;ACXF;;AAjBA;EACE,yBAAA;AAoBF;;AAjBA;EACE,qBAAA;EACA,sBAAA;AAoBF;;AAlBA;EACE,qBAAA;EACA,sBAAA;AAqBF;;AAlBA;EACE,+CAAA;AAqBF;;AAlBA;EACE,+CAAA;AAqBF","sourcesContent":["$color-primary: #007bff;\n\n$color-background: rgba(239, 238, 238, 0.71);\n$color-light-grey: #dacece;\n$color-grey: #c5c5cb;\n$color-light-black: #3a3939;\n\n$size-large: 40px;\n$size-medium: 24px;\n$size-big-normal: 18px;\n$size-normal: 15px;\n\nbody {\n  background-color: $color-background;\n}\n\n.border_shadow {\n  box-shadow: 0 3px 6px #00000029;\n}\n\n.border {\n  border: 1px solid $color-light-grey;\n}\n\n:global(.mapboxgl-ctrl-geocoder) {\n  z-index: 0!important;\n}\n\n:global(.mapboxgl-ctrl-bottom-left),\n:global(.mapboxgl-ctrl-bottom-righ),\n:global(.mapboxgl-ctrl-top-left),\n:global(.mapboxgl-ctrl-top-right){\n  z-index: 0!important;\n}\n","@import \"src/styles.module\";\n\n$size: 2em;\n\n.alert_message {\n  font-size: $size!important;\n}\n\n:global(.css-1vooibu-MuiSvgIcon-root) {\n  width: $size!important;\n  height: $size!important;\n}\n:global(.css-ptiqhd-MuiSvgIcon-root) {\n  width: $size!important;\n  height: $size!important;\n}\n\n:global(.css-1hdyuqk-MuiPaper-root-MuiAlert-root) {\n  background-color: rgb(255 180 180)!important;\n}\n\n:global(.css-wnkddu-MuiPaper-root-MuiAlert-root) {\n  background-color: rgb(237, 247, 180)!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"border_shadow": `SnackBarType_border_shadow__PuxRb`,
	"border": `SnackBarType_border__FzvS0`,
	"alert_message": `SnackBarType_alert_message__0+1+3`
};
export default ___CSS_LOADER_EXPORT___;
