import React, {HTMLInputTypeAttribute} from 'react';
import styles from "./TextInput.module.scss";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Input} from "@mui/material";

export default function TextInput({ type, label,name,required, value, onChange, className,onkeyup,error }: TextInputProps){
    return (
        <div className={styles.main_container}>
            <label className={styles.text_style}>{label}</label>
            {type !== "date" ?
                <Input
                    type={type}
                    required={required}
                    value={value}
                    name={name}
                    error={error}
                    onKeyUp={(e) => onkeyup ? onkeyup(e) : null}
                    onChange={(e) => onChange(e)}
                    className={[styles.input, className].join(" ")}
                /> :
                <ReactDatePicker
                    selected={new Date(value)}
                    required={required}
                    name={name}
                    onChange={(date) => onChange(date)}
                    dateFormat={"dd/MM/yyyy"}
                    className={[styles.input, className].join(" ")}
                />}
        </div>
    );
};

interface TextInputProps {
    type: HTMLInputTypeAttribute;
    label: string;
    name?: string;
    required?: boolean;
    value: string|number;
    onChange: (value: any) => void;
    className?: string;
    onkeyup?: (value: any) => void;
    error?:boolean
}
