import { Outlet } from "react-router-dom";
import Sidebar from "../features/navbar/components/Sidebar";
import {StyledEngineProvider} from "@mui/material";

export default function SideBarLayout(){
    return(
        <StyledEngineProvider injectFirst>
            <Sidebar children={<Outlet />} />
        </StyledEngineProvider>
    )
};
