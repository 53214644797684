import React, {useContext, useEffect, useState} from "react";

import TextInput from "../components/form/TextInput";
import {useNavigate} from "react-router-dom";
import styles from "./Login.module.scss"
import {login} from "../api/Auth";
import {
    HOME_PAGE,
    SESSION_STORAGE_LOGGED,
    LOCAL_STORAGE_SAVED_USERNAME,
    LOCAL_STORAGE_TOKEN,
    USER_PAGE
} from "../utils/Constant";
import {AuthContext} from "../context/AuthContext";
import i18n from "i18next";
import Logo from "../logo.svg";
import {AxiosError, AxiosResponse} from "axios";
import {AuthResponse} from "../models/AuthResponse";
import {ErrorResponse} from "../models/ErrorResponse";
import {SnackBarContext} from "../features/snackbar/context/SnackBarContext";
import {Button} from "@mui/material";
import PasswordInput from "../components/form/PasswordInput";
import {handleErrorMessage} from "../utils/Common";

const Login: React.FC = () => {
    const {setLogged} = useContext(AuthContext);
    const getRememberedUsernameFromLocalStorage = () => {
        return localStorage.getItem(LOCAL_STORAGE_SAVED_USERNAME) || '';
    };
    const {setSnackBarType, setOpen, setMessage} = useContext(SnackBarContext);
    const [username, setUsername] = useState(getRememberedUsernameFromLocalStorage());
    const [password, setPassword] = useState("");
    const [saveUsername, setSaveUsername] = useState(getRememberedUsernameFromLocalStorage() !== '');
    const [capsLock, setCapsLock] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        //To be sure that token is clear
        localStorage.removeItem(LOCAL_STORAGE_TOKEN);
    })

    const checkCapsLock = (event:React.KeyboardEvent) => {
        if (event.getModifierState('CapsLock')) {
            setCapsLock(true);
        } else {
            setCapsLock(false);
        }
    };
    const handleUsernameChange = (value: any) => {
        setUsername(value.target.value);
    };

    const handlePasswordChange = (value: any) => {
        setPassword(value.target.value);

    };

    const storeUsernameInLocalStorage = (username:string) => {
        localStorage.setItem(LOCAL_STORAGE_SAVED_USERNAME, username);
    };


    const handleLogin = () => {
        login(username, password)
            .then((response: AxiosResponse<AuthResponse>) => {
                localStorage.setItem(LOCAL_STORAGE_TOKEN, response.data.token);
                sessionStorage.setItem(SESSION_STORAGE_LOGGED, "true");
                if(saveUsername){
                    storeUsernameInLocalStorage(username);
                }
                setLogged(true);
                navigate(HOME_PAGE);
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                setSnackBarType("error")
                setMessage(handleErrorMessage(error));
                setOpen(true);
            });
    };

    return (
        <div className={styles.main_container}>
            <div className={styles.body_container}>
                <div className={styles.top_container}>
                    <img alt={""} src={Logo} className={styles.img}/>
                    <label className={styles.main_label}>{i18n.t("login.title")}</label>
                    <label className={styles.sub_label}>{i18n.t("login.sub_title")}</label>
                </div>

                <TextInput
                    type={"text"}
                    label={i18n.t("login.username")}
                    onChange={handleUsernameChange}
                    value={username}
                    className={styles.text_input}
                />
                <PasswordInput
                        label={i18n.t("login.password")}
                        value={password}
                        onChange={handlePasswordChange}
                        onkeyup={checkCapsLock}
                        className={styles.text_input}
                />

                {capsLock ? <label>{i18n.t("login.caps_lock")}</label> : null}
                <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                    <label className={styles.remember_me_label} onClick={(e:any)=>e.stopPropagation()}>{i18n.t("login.remember_me")}
                    </label>
                    <input type="checkbox"
                           checked={saveUsername}
                           onClick={() => setSaveUsername(!saveUsername)}
                           className={styles.remember_me_checkbox}
                    />
                </div>
                <Button variant={"contained"}
                        className={styles.button}
                        onClick={handleLogin}>
                    {i18n.t("login.log")}
                </Button>
            </div>
        </div>
    );
}
export default Login;
