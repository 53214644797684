import styles from "./ProjectDetailPage.module.scss";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {Project} from "../features/management/models/Project";
import {Button} from "@mui/material";
import i18n from "../i18n";
import {dateformat} from "../utils/Dateformat";
import ReportHistory from "../features/report/components/ReportHistory";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import {downloadAllImagesByProject} from "../api/project";
import {LinearProgress} from "@mui/material";

export default function ProjectDetailPage() {
    const {state} = useLocation();
    const navigate = useNavigate();
    const [project, setProject] = useState<Project>(new Project());
    const [downloading, setDownloading] = useState<boolean>(false);

    useEffect(() => {
        if (state && state.project) {
            setProject(state.project);
        }
    }, [state])

    const downloadAllImages = () => {
        setDownloading(true)
        downloadAllImagesByProject(project.projectId, project.name).then((response) => {
            const blob = new Blob([response.data], {type: 'application/zip'});

            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = project.name+'.zip';

            link.click();

            window.URL.revokeObjectURL(url);
            setDownloading(false)
        }).catch((error) => {
            console.log(error)
            setDownloading(false)
        });
    }

    return (
        <div className={styles.main_container}>
            <Button onClick={() => navigate(-1)} variant={"contained"}>
                <KeyboardArrowLeft/>
                {i18n.t("common.back")}
            </Button>
            {/*
            <div className={styles.project_container}>
                <div>
                    <label className={styles.text}>{i18n.t("header.project.name") + ": "}</label>
                    <label className={styles.text_value}>{project.name}</label>
                </div>
                <div>
                    <label className={styles.text}>{i18n.t("header.project.address") + ": "}</label>
                    <label className={styles.text_value}>{project.adress}</label>
                </div>
                <div>
                    <label className={styles.text}>{i18n.t("header.project.start_activity") + ": "}</label>
                    <label className={styles.text_value}>{dateformat(project.activeWindowStart)}</label>
                </div>
                <div>
                    <label className={styles.text}>{i18n.t("header.project.end_activity") + ": "}</label>
                    <label className={styles.text_value}>{dateformat(project.activeWindowEnd)}</label>
                </div>
            </div>*/}
            {/*  <div className={styles.project_container}>
                <div>
                    <label className={styles.label}>{i18n.t("header.project.name") + ": "}</label>
                    <label className={styles.text_value}>{project.name}</label>
                </div>
                <div>
                    <label className={styles.label}>{i18n.t("header.project.address") + ": "}</label>
                    <label className={styles.text_value}>{project.adress}</label>
                </div>
                <div>
                    <label className={styles.label}>{i18n.t("header.project.start_activity") + ": "}</label>
                    <label className={styles.text_value}>{dateformat(project.activeWindowStart)}</label>
                </div>
                <div>
                    <label className={styles.label}>{i18n.t("header.project.end_activity") + ": "}</label>
                    <label className={styles.text_value}>{dateformat(project.activeWindowEnd)}</label>
                </div>            </div>*/}
            <div className={styles.project_container}>
                <div className={styles.column_20}>
                    <div className={styles.icon}>
                        {/* Add your building icon here */}
                        <HouseOutlinedIcon style={{fontSize: 100, color: '#939DAD'}}/>
                    </div>
                </div>
                <div className={styles.column_50}>
                    <div className={styles.row}>
                        <span className={styles.label}>{i18n.t("header.project.name")}:</span>
                        <span className={styles.text_value}>{project.name}</span>
                    </div>
                    <div className={styles.row}>
                        <span className={styles.label}>{i18n.t("header.project.address")}:</span>
                        <span className={styles.text_value}>{project.adress}</span>
                    </div>
                </div>
                <div className={styles.column_30}>
                    <div className={styles.row}>
                        <span className={styles.label}>{i18n.t("header.project.start_activity")}:</span>
                        <span className={styles.text_value}>{dateformat(project.activeWindowStart)}</span>
                    </div>
                    <div className={styles.row}>
                        <span className={styles.label}>{i18n.t("header.project.end_activity")}:</span>
                        <span className={styles.text_value}>{dateformat(project.activeWindowEnd)}</span>
                    </div>
                </div>
            </div>
            {downloading ? <div>
                    <p>{i18n.t("common.downloading")}</p>
                    <LinearProgress color="success"/>
                </div> :
                <div style={{textAlign:'right'}}>
                    <Button variant="contained" startIcon={<DownloadIcon/>} onClick={downloadAllImages}>
                        {i18n.t("common.download")}
                    </Button>
                </div>}


            <ReportHistory projectId={project.projectId}/>
        </div>
    )
}
