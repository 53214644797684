import React, {useContext} from "react";
import {Navigate} from "react-router-dom";
import {AuthContext} from "../context/AuthContext";
import {LOGIN_PAGE} from "../utils/Constant";
import SideBarLayout from "./SideBarLayout";
const PrivateRoute = () => {
    const {logged} = useContext(AuthContext);

    return (
        logged ? <SideBarLayout /> : <Navigate to={LOGIN_PAGE} replace />
    )

};
export default PrivateRoute;
