export class CreateClientRequest{
    username: string;
    password: string;
    email: string;
    activeWindowStart: number;
    activeWindowEnd: number;
    active: boolean;

    constructor() {
        this.username = '';
        this.password = '';
        this.email = '';
        this.activeWindowEnd = new Date().getTime() + 86400000;
        this.activeWindowStart = new Date().getTime() ;
        this.active = true;
    }
}
