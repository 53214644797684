import styles from "./SnackBarType.module.scss";
import {Alert, Slide, Snackbar} from "@mui/material";
import React, {Dispatch, SetStateAction} from "react";
import {SnackBarType} from "../models/SnackBarType";

export default function SnackBarMessage({open, setOpen, snackBarType, message}: SnackBarMessageProps) {

    const closeHandler = () => {
        setOpen(false);
    }

    const TransitionLeft = (props: any) =>
        <Slide {...props} direction={"left"}/>


    return (
        <Snackbar open={open}
                  anchorOrigin={{vertical: 'top', horizontal: "center"}}
                  sx={{marginTop: "10%",display: (open? "visible" : "none")}}
                  autoHideDuration={100000}
                  TransitionComponent={TransitionLeft}
                  onClose={closeHandler}
        >
            <Alert onClose={closeHandler}
                   className={styles.alert_message}
                   severity={snackBarType}>
                {message}
            </Alert>
        </Snackbar>
    )
}

interface SnackBarMessageProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    snackBarType: SnackBarType;
    message: string;
}
