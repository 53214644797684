import client from "./Client";


const PATH = "identity/auth"
const login = (username: string, password: string) =>
    client.post(PATH + "/login", {
        username: username,
        password: password
    })

export {
    login
}
