import React from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';

import Home from './pages/Home';
import Login from './pages/Login';
import PrivateRoute from "./components/PrivateRoute";
import {
    CLIENT_PAGE,
    HOME_PAGE,
    LOGIN_PAGE,
    PROJECT_PAGE,
    PROJECT_DETAIL_PAGE,
    REPORTING_PAGE,
    USER_PAGE
} from "./utils/Constant";
import UserManagement from "./pages/UserManagement";
import ProjectManagement from "./pages/ProjectManagement";
import ClientManagement from "./pages/ClientManagement";
import Reporting from "./pages/Reporting";
import ProjectDetailPage from "./pages/ProjectDetailPage";

const AppRouter: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path={LOGIN_PAGE} Component={Login} />

                <Route path={"/"} element={<PrivateRoute/>}>
                    <Route path={HOME_PAGE} element={<Home />}></Route>
                    {/*<Route path={USER_PAGE} element={<UserManagement />}></Route>*/}
                    <Route path={PROJECT_PAGE} element={<ProjectManagement />}></Route>
                    <Route path={PROJECT_DETAIL_PAGE} element={<ProjectDetailPage/>}></Route>
                    <Route path={CLIENT_PAGE} element={<ClientManagement />}></Route>
                    <Route path={REPORTING_PAGE} element={<Reporting/>}></Route>
                </Route>
                <Route path={"*"} element={<Navigate to={LOGIN_PAGE}/>}/>
            </Routes>
        </Router>
    );
};
export default AppRouter;
