import {Client} from "../Client";
import {Project} from "../Project";

export class Report {
    id: number;
    name: string;
    description: string;
    path: string;
    type: ReportType;
    status: ReportStatus;
    client: Client;
    project: Project;
    imageNumber: number;
    reporter: string;
    creationDate:string;
}

export type ReportType = "INCIDENT" | ""
export type ReportStatus = "PENDING" | "REJECTED" | "ACCEPTED"
