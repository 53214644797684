import {Button, Checkbox, TableCell} from "@mui/material";
import styles from "./TableFormComponent.module.scss";
import {dateformat} from "../../utils/Dateformat";

export default function TableRowRender({type, data, onClick}: TableRowRenderProps) {

    const renderRow = (type: ColumnType, data: any) => {
        switch (type) {
            case "active":
                return <TableCell align="center">
                    <span className={data ? styles.bullet_green : styles.bullet_red}></span>
                </TableCell>
            case "button":
                return <TableCell>
                    <Button onClick={onClick}>{data}</Button>
                </TableCell>
            case "checkbox":
                return <Checkbox
                    color="primary"
                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                    // checked={rowCount > 0 && numSelected === rowCount}
                    // onChange={onSelectAllClick}
                    inputProps={{
                        'aria-label': 'select all desserts',
                    }}
                />
            case "clickable":
                return <TableCell onClick={onClick}
                                  className={styles.label_clickable}>
                    {data}
                </TableCell>
            case "date":
                return <TableCell align="left">
                    {dateformat(data)}
                </TableCell>
            case "number":
            case "string":
            default:
                return <TableCell align="left">{data}</TableCell>
        }
    }

    return (<>{renderRow(type, data)}</>)
}

interface TableRowRenderProps {
    type: ColumnType,
    data: any,
    onClick?: () => void,
}

export type ColumnType = "string" | "number" | "active" | "date" | "button" | "clickable" | "checkbox";
