// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportModal_modal__e3u4z {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReportModal_mainContainer__XxwCC {
  display: flex;
  flex-direction: column;
  background: white;
  width: 90vh;
  height: 80%;
  border-radius: 6px;
  padding: 20px;
}

.ReportModal_topContainer__WqPXe {
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 15px;
}

.ReportModal_topTextContainer__I7U6p {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  justify-content: center;
}

.ReportModal_buttonContainer__WKjue {
  display: flex;
  align-items: center;
}

.ReportModal_carouselImages__UuHDj {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ReportModal_img__0uArW {
  max-width: 100%;
  max-height: 60vh;
  object-fit: contain;
  border-radius: 6px;
}

.ReportModal_text__d8pjk {
  font-size: 16px;
  margin: 5px 0;
}

.ReportModal_textBold__QCKOv {
  font-weight: bold;
}

.ReportModal_icon__q8z59 {
  font-size: 80px;
  color: #939DAD;
}`, "",{"version":3,"sources":["webpack://./src/components/modal/ReportModal.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,WAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,aAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;AACF","sourcesContent":[".modal {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.mainContainer {\n  display: flex;\n  flex-direction: column;\n  background: white;\n  width: 90vh;\n  height: 80%;\n  border-radius: 6px;\n  padding: 20px;\n}\n\n.topContainer {\n  display: flex;\n  flex-direction: row;\n  background-color: #f5f5f5;\n  border: 1px solid #e0e0e0;\n  border-radius: 6px;\n  padding: 10px;\n  margin-bottom: 15px;\n}\n\n.topTextContainer {\n  display: flex;\n  flex-direction: column;\n  margin-left: 20px;\n  justify-content: center;\n}\n\n.buttonContainer {\n  display: flex;\n  align-items: center;\n}\n\n.carouselImages {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.img {\n  max-width: 100%;\n  max-height: 60vh;\n  object-fit: contain;\n  border-radius: 6px;\n}\n\n.text {\n  font-size: 16px;\n  margin: 5px 0;\n}\n\n.textBold {\n  font-weight: bold;\n}\n\n.icon {\n  font-size: 80px;\n  color: #939DAD;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `ReportModal_modal__e3u4z`,
	"mainContainer": `ReportModal_mainContainer__XxwCC`,
	"topContainer": `ReportModal_topContainer__WqPXe`,
	"topTextContainer": `ReportModal_topTextContainer__I7U6p`,
	"buttonContainer": `ReportModal_buttonContainer__WKjue`,
	"carouselImages": `ReportModal_carouselImages__UuHDj`,
	"img": `ReportModal_img__0uArW`,
	"text": `ReportModal_text__d8pjk`,
	"textBold": `ReportModal_textBold__QCKOv`,
	"icon": `ReportModal_icon__q8z59`
};
export default ___CSS_LOADER_EXPORT___;
