// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: rgba(239, 238, 238, 0.71);
}

.TextInput_border_shadow__evLo4 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.TextInput_border__WSHbW {
  border: 1px solid #dacece;
}

.mapboxgl-ctrl-geocoder {
  z-index: 0 !important;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-righ,
.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-right {
  z-index: 0 !important;
}

.TextInput_main_container__mbfnY {
  display: flex;
  flex-direction: column;
}

.TextInput_text_style__w7QN- {
  font-size: 15px;
  color: #3a3939;
  margin-bottom: 1%;
}

.TextInput_input__DukzQ {
  border: 1px solid #E7E8EB;
  border-radius: 6px;
  padding: 15px;
  font-size: 18px;
  color: #3a3939;
}`, "",{"version":3,"sources":["webpack://./src/styles.module.scss","webpack://./src/components/form/TextInput.module.scss"],"names":[],"mappings":"AAYA;EACE,2CAXiB;ACAnB;;ADcA;EACE,iDAAA;ACXF;;ADcA;EACE,yBAAA;ACXF;;ADcA;EACE,qBAAA;ACXF;;ADcA;;;;EAIE,qBAAA;ACXF;;AAnBA;EACE,aAAA;EACA,sBAAA;AAsBF;;AAnBA;EACE,eDEY;ECDZ,cDJkB;ECKlB,iBAAA;AAsBF;;AAnBA;EACE,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,eDRgB;ECShB,cDbkB;ACmCpB","sourcesContent":["$color-primary: #007bff;\n\n$color-background: rgba(239, 238, 238, 0.71);\n$color-light-grey: #dacece;\n$color-grey: #c5c5cb;\n$color-light-black: #3a3939;\n\n$size-large: 40px;\n$size-medium: 24px;\n$size-big-normal: 18px;\n$size-normal: 15px;\n\nbody {\n  background-color: $color-background;\n}\n\n.border_shadow {\n  box-shadow: 0 3px 6px #00000029;\n}\n\n.border {\n  border: 1px solid $color-light-grey;\n}\n\n:global(.mapboxgl-ctrl-geocoder) {\n  z-index: 0!important;\n}\n\n:global(.mapboxgl-ctrl-bottom-left),\n:global(.mapboxgl-ctrl-bottom-righ),\n:global(.mapboxgl-ctrl-top-left),\n:global(.mapboxgl-ctrl-top-right){\n  z-index: 0!important;\n}\n","@import \"src/styles.module\";\n\n.main_container {\n  display: flex;\n  flex-direction: column;\n}\n\n.text_style {\n  font-size: $size-normal;\n  color: $color-light-black;\n  margin-bottom: 1%;\n}\n\n.input {\n  border: 1px solid #E7E8EB;\n  border-radius: 6px;\n  padding: 15px;\n  font-size: $size-big-normal;\n  color: $color-light-black;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"border_shadow": `TextInput_border_shadow__evLo4`,
	"border": `TextInput_border__WSHbW`,
	"main_container": `TextInput_main_container__mbfnY`,
	"text_style": `TextInput_text_style__w7QN-`,
	"input": `TextInput_input__DukzQ`
};
export default ___CSS_LOADER_EXPORT___;
