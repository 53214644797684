import {ITableColumn} from "../../../components/table/TableHeaderSort";
import {Report} from "../../management/models/report/Report";
import i18n from "../../../i18n";
import {useContext, useEffect, useState} from "react";
import {SnackBarContext} from "../../snackbar/context/SnackBarContext";
import ReportModal from "../../../components/modal/ReportModal";
import {PaginatedResponse} from "../../management/models/PaginatedResponse";
import {TableRender} from "../../../components/table/TableFormComponent";
import {deleteReport, searchReports} from "../../../api/report";
import {ErrorResponse} from "../../../models/ErrorResponse";
import {AxiosError, AxiosResponse} from "axios";
import {ReportPaginationRequest} from "../models/ReportPaginationRequest";
import DeleteModal from "../../../components/modal/DeleteModal";
import {handleErrorMessage} from "../../../utils/Common";


export default function ReportHistory({projectId}: ReportHistoryProps) {
    const [data, setData] = useState<PaginatedResponse<Report>>(new PaginatedResponse());
    const {setSnackBarType, setOpen, setMessage} = useContext(SnackBarContext);
    const [fts, setFts] = useState<string>("");
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [sorting, setSorting] = useState<string>("report_id:desc");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [report, setReport] = useState<Report>(new Report());
    const [selected, setSelected] = useState<Report[]>([]);

    const tableColumn: ITableColumn<Report>[] = [
        {
            headerTitle: i18n.t("header.report.name"),
            sortName: "name",
            data: (report: Report) => report.name,
            type: "string",
        },
        {
            headerTitle: i18n.t("header.report.type"),
            sortName: "type",
            data: (report: Report) => i18n.t("report.type." + report.type),
            type: "string",
            disable: true,
        },
        {
            headerTitle: i18n.t("header.report.status"),
            sortName: "status",
            data: (report: Report) => i18n.t("report.status." + report.status),
            type: "string",
            disable: true,
        },
        {
            headerTitle: i18n.t("header.report.description"),
            sortName: "description",
            data: (report: Report) => report.description,
            type: "string",
        },
        {
            headerTitle: i18n.t("header.report.image"),
            sortName: "image",
            data: (report: Report) => i18n.t("report.see_img"),
            type: "button",
            onClick: (report: Report) => {
                handleRowClick(report)
            },
            disable: true,
        },
    ]

    useEffect(() => {
        search();
    }, [projectId, sorting, fts, rowsPerPage, page])

    const search = () => {
        setSelected([]);
        if (projectId) {
            let request: ReportPaginationRequest = {
                order: sorting,
                fts: fts,
                row: rowsPerPage,
                offset: rowsPerPage * page,
                projectId: projectId
            }

            searchReports(request).then((response: AxiosResponse<PaginatedResponse<Report>>) => {
                setData(response.data);
            }).catch((error: AxiosError<ErrorResponse>) => {
                setSnackBarType("error")
                setMessage(handleErrorMessage(error));
                setOpen(true);
            });
        }
    }
    const handleRowClick = (data: Report) => {
        setReport(data);
        setShowModal(true);
    }
    const handleClose = () => {
        setReport(new Report());
        setShowModal(false)
    }

    const handlePageChange = (event: unknown, page: number) => {
        setPage(page);
    }
    const handleDelete = () => {
        selected.forEach((value) => {
            deleteReport(value.id).then(r => {
                search();
            }).catch(error => {
                console.log(error)
            })
        })
        setShowDeleteModal(false);
    }
    return (
        <div>
            <TableRender title={i18n.t("report.history_title")}
                         data={data}
                         tableColumn={tableColumn}
                         page={page}
                         handlePageChange={handlePageChange}
                         rowsPerPage={rowsPerPage}
                         handleRowChange={setRowsPerPage}
                         orderTable={setSorting}
                         editable={false}
                         deletable={true}
                         checkable={true}
                         order={sorting}
                         selected={selected}
                         setSelected={setSelected}
                         onDeleteClick={() => setShowDeleteModal(true)}
                         setFts={setFts}/>

            <ReportModal open={showModal}
                         handleClose={handleClose}
                         report={report}/>
            <DeleteModal open={showDeleteModal}
                         handleDelete={handleDelete}
                         selectedNumber={selected.length}
                         handleClose={() => setShowDeleteModal(false)}/>
        </div>
    )
}

interface ReportHistoryProps {
    projectId: number;
}
