import {Checkbox, TableCell, TableSortLabel} from "@mui/material";
import {ColumnType} from "./TableRowRender";
import {useEffect, useState} from "react";

export default function TableHeaderSort<T>({column, order, orderTable}: TableHeaderSortProps<T>) {
    const [orderBy, setOrderBy] = useState<string>("");
    const [orderDirection, setOrderDirection] = useState<OrderDirection>();


    useEffect(() => {
        let splitOrder = order?.split(":");
        splitOrder[0] ? setOrderBy(splitOrder[0]) : setOrderBy("");

        if (splitOrder[1]) {
            let direction = splitOrder[1];
            if (direction === "asc" || direction === "desc"){
                setOrderDirection(direction);
            }
        }
    }, [order])

    const handleSort = (property: string, direction: OrderDirection | undefined) => (event: any) => {
        let splitOrder = order?.split(":");
        if (splitOrder[0] === property) {
            if (splitOrder[1] === "asc") {
                orderTable(splitOrder[0] + ":" + "desc");
            } else if (splitOrder[1] === "desc") {
                orderTable("");
            } else {
                orderTable(splitOrder[0] + ":" + "asc");
            }
        } else  {
            orderTable(property + ":" + "asc");
        }
    }

    return(
        <>
            <TableCell sortDirection={orderDirection ? orderDirection : false}>
                <TableSortLabel
                    disabled={column.disable}
                    active={orderBy === column.sortName}
                    direction={orderDirection === 'desc' ? 'desc' : 'asc'}
                    onClick={handleSort(column.sortName, column.sortDirection)}
                >
                    {column.headerTitle}
                </TableSortLabel>
            </TableCell>
        </>
    )
}

interface TableHeaderSortProps<T> {
    column: ITableColumn<T>;
    order: string;
    orderTable: (value: string) => void;
}

export interface ITableColumn<T> {
    headerTitle: string;
    sortName: string;
    sortDirection?: OrderDirection;
    data: (data: T) => any;
    type: ColumnType;
    onClick?: (data: T) => void;
    disable?: boolean;
}
export type OrderDirection = "asc" | "desc";
