import styles from "./Sidebar.module.scss";
import Logo from "../../../logo.svg";
import {Box, Divider, List} from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import LogoutIcon from '@mui/icons-material/Logout';
import ReportIcon from '@mui/icons-material/Report';
import {ReactElement, useEffect, useState} from "react";
import i18n from "../../../i18n";
import {useNavigate} from "react-router-dom";
import {
    CLIENT_PAGE,
    LOCAL_STORAGE_NAVBAR,
    LOGIN_PAGE,
    PROJECT_PAGE,
    REPORTING_PAGE,
    USER_PAGE
} from "../../../utils/Constant";
import SideListItemCard from "./SideListItemCard"

const drawerWidth = 240;
const minWidth = 1200;
export default function Sidebar({children}: SideBarProps) {
    const [selectedIndex, setSelectedIndex] = useState(
        localStorage.getItem(LOCAL_STORAGE_NAVBAR) ? Number(localStorage.getItem(LOCAL_STORAGE_NAVBAR)) : 2);
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);

    useEffect(() => {
        handleListItemClick(selectedIndex, undefined);
        handleResize();
        window.addEventListener('resize', handleResize);
    }, [])

    const handleResize = () => {
        setOpen(window.innerWidth > minWidth)
    }

    const handleListItemClick = (index: number, page: string | undefined) => {
        setSelectedIndex(index);
        if (index !== 0) {
        localStorage.setItem(LOCAL_STORAGE_NAVBAR, index.toString());
    }
        if (page) {
        navigate(page);
        } else {
        nav.map(value => {
            if (value.value === index) {
            return navigate(value.redirectPage);
        }
        })
        }
    };

    return(
        <Box sx={{ display:'flex' }}>
            <Drawer variant={"permanent"} open={open}>
                <DrawerHeader>
                    {open ?
                        <div className={styles.top_container}>
                            <img alt={""} src={Logo} className={styles.img}/>
                            <div className={styles.top_label_container}>
                                <label className={styles.management_label}>
                                    {i18n.t("nav.title")}
                                </label>
                                <label className={styles.sub_label}>
                                       {i18n.t("nav.sub_title")}
                                </label>
                            </div>
                        </div>
                        :
                        <img alt={""} src={Logo} className={styles.img}/>
                    }
                </DrawerHeader>
                <Divider/>
                <List component="nav" >
                {nav.map((page) =>
                    <SideListItemCard key={page.value}
                                      selectedIndex={selectedIndex}
                                      open={open}
                                      text={page.text}
                                      value={page.value}
                                      redirectPage={page.redirectPage}
                                      icon={page.icon}
                                      handleClick={handleListItemClick}/>
                )}
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow:1, p:3 }}>
                {children}
            </Box>
</Box>
)
}

interface SideBarProps {
    children: ReactElement
}

const nav = [
    // {
    //     text: i18n.t("nav.user"),
    //     value: 1,
    //     redirectPage: USER_PAGE,
    //     icon: <PersonIcon/>
    // },
    {
        text: i18n.t("nav.project"),
        value: 2,
        redirectPage: PROJECT_PAGE,
        icon: <BusinessIcon/>
    },
    {
        text: i18n.t("nav.client"),
        value: 3,
        redirectPage: CLIENT_PAGE,
        icon: <PersonIcon/>
    },
    {
        text: i18n.t("nav.reporting"),
        value: 4,
        redirectPage: REPORTING_PAGE,
        icon: <ReportIcon/>
    },
    {
        text: i18n.t("nav.logout"),
        value: 0,
        redirectPage: LOGIN_PAGE,
        icon: <LogoutIcon/>
    }
]

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));
