import i18n from "../../i18n";
import {TablePagination} from "@mui/material";
import {PaginatedResponse} from "../../features/management/models/PaginatedResponse";
import {Dispatch, SetStateAction} from "react";

export default function TablePaginationCustom<T>({data, page, rowsPerPage, handlePageChange, handleRowChange}: TablePaginationCustomProps<T>) {
    const rowPerPageOptions = [5, 10, 25, 50, 100];

    const changeRow = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleRowChange(Number(event.target.value));
        //set page to 0
        handlePageChange(null, 0);
    }

    return(
        <TablePagination
            rowsPerPageOptions={rowPerPageOptions}
            labelDisplayedRows={paginationInfo => {
                return i18n.t("pagination.page") + " " +
                    (paginationInfo.page + 1) + " " +
                    i18n.t("pagination.on") + " " +
                    (data?.totalPages ?? 0);
            }}
            labelRowsPerPage={i18n.t("pagination.rows_per_page")}
            component="div"
            count={data?.totalElements ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={changeRow}
        />
    )
}

interface TablePaginationCustomProps<T> {
    data: PaginatedResponse<T>;
    page: number;
    handlePageChange: (event: unknown, page: number) => void;
    rowsPerPage: number;
    handleRowChange: Dispatch<SetStateAction<number>>;
}
