import styles from "./UpdatePassword.module.scss";
import {Button, Modal} from "@mui/material";
import i18n from "../../i18n";
import React, {useContext, useState} from "react";
import {updateClientPassword} from "../../api/clients";
import {SnackBarContext} from "../../features/snackbar/context/SnackBarContext";
import {AxiosError} from "axios/index";
import {ErrorResponse} from "../../models/ErrorResponse";
import {AxiosResponse} from "axios";
import {Client} from "../../features/management/models/Client";
import PasswordInput from "../form/PasswordInput";
import {handleErrorMessage} from "../../utils/Common";

export default function UpdatePassword({open, onClose, id}: UpdatePasswordProps) {
    const [password, setPassword] = useState<string>("");
    const {setSnackBarType, setOpen, setMessage} = useContext(SnackBarContext);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
    };

    const handleValid = () => {
        updateClientPassword(id, password)
            .then((response: AxiosResponse<Client>) => {
                setSnackBarType("success")
                setMessage(i18n.t("client.success_update_pwd"));
                setOpen(true);
                onClose();
            })
            .catch((error: AxiosError<ErrorResponse>) => {
                setSnackBarType("error")
                setMessage(handleErrorMessage(error));
                setOpen(true);
                onClose();
            });
    }

    return(
        <Modal open={open} onClose={onClose} className={styles.modal}>
            <div className={styles.main_container}>
                <div className={styles.text_container}>
                    <label className={styles.text}>{i18n.t("client.new_pwd") }</label>
                    <PasswordInput
                        label={i18n.t("client.password")}
                        name="password"
                        value={password}
                        limit={4}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className={styles.button_container}>
                    <Button onClick={onClose}
                            color={"error"}
                            variant={"contained"}>
                        {i18n.t("common.cancel")}
                    </Button>
                    <Button onClick={handleValid}
                            color={"success"}
                            variant={"contained"}>
                        {i18n.t("common.confirm")}
                    </Button>
                </div>


            </div>
        </Modal>
    )
}

interface UpdatePasswordProps {
    open: boolean;
    onClose: () => void;
    id: number;
}
