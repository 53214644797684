import styles from "./TableFormComponent.module.scss";
import {Button} from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import i18n from "../../i18n";
import {Dispatch, FunctionComponent, SetStateAction} from "react";

export default function FormRender<T extends Object>({data, setShowForm, FormComponent}: FormRenderProps<T>) {

    return (
        <div>
            <div className={styles.top_container}>
                <Button variant={"contained"}
                        className={styles.button}
                        onClick={() => setShowForm(false)}
                        startIcon={<ArrowBack/>}
                >{i18n.t("common.back")}</Button>
            </div>
            <FormComponent data={data} setShowForm={setShowForm}/>
        </div>
    )
}

interface FormRenderProps<T> {
    data?: T;
    setShowForm: Dispatch<SetStateAction<boolean>>;
    FormComponent: FunctionComponent<IFormDataProps<T>>;
}

export interface IFormDataProps<T> {
    data?: T;
    setShowForm: Dispatch<SetStateAction<boolean>>;
}
