import {Client} from "./Client"

export class Project {
    projectId: number;
    name: string;
    activeWindowStart: Date;
    activeWindowEnd: Date;
    isActive: boolean;
    adress: string;
    coordinates: any;
    clients: Client[];
    reporter: string;
}
