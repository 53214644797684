import {ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {ReactElement} from "react";

export default function SideListItemCard({selectedIndex,
                                             open,
                                             value,
                                             redirectPage,
                                             icon,
                                             text,
                                             handleClick}: SideListItemCardProps) {
    return (
        <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
                selected={selectedIndex === value}
                sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                }}
                onClick={() => handleClick(value, redirectPage)}
            >
                <ListItemIcon sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                }}>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
        </ListItem>
    )
}

interface SideListItemCardProps {
    selectedIndex: number;
    open: boolean;
    text: string;
    value: number;
    redirectPage: string;
    icon: ReactElement;
    handleClick: (index: number, page: string) => void;
}
