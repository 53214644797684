export class CreateProjectRequest {

    name: string;
    activeWindowStart: number;
    activeWindowEnd: number;
    isActive: boolean;
    adress:string;
    coordinates : DrawnShapeCoordinates;

    constructor() {
        this.name = '';
        this.activeWindowStart = new Date().getTime();
        this.activeWindowEnd = new Date().getTime() + 86400000;
        this.isActive = true;
        this.adress = "";
        this.coordinates = {} as DrawnShapeCoordinates;
    }
}
type DrawnShapeCoordinates = {
    bottomLeft: [number, number];
    bottomRight: [number, number];
    topRight: [number, number];
    topLeft: [number, number];
};
