// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: rgba(239, 238, 238, 0.71);
}

.Sidebar_border_shadow__VFclP, .Sidebar_main_container__5Envw {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.Sidebar_border__347yx {
  border: 1px solid #dacece;
}

.mapboxgl-ctrl-geocoder {
  z-index: 0 !important;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-righ,
.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-right {
  z-index: 0 !important;
}

.Sidebar_main_container__5Envw {
  background-color: white;
  width: 15%;
  min-height: 100vh;
  flex-shrink: 0;
}

.Sidebar_top_container__W6bLn {
  display: flex;
  padding: 10px;
  align-items: center;
}

.Sidebar_top_label_container__LCAWl {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.Sidebar_img__\\+Ldaj {
  width: 75px;
}

.Sidebar_management_label__5H36t {
  font-size: 22px;
  line-height: 1.43;
  font-weight: 600;
  color: rgb(50, 50, 77);
}

.Sidebar_sub_label__RwuXx {
  font-size: 16px;
  line-height: 1.33;
  color: rgb(102, 102, 135);
}`, "",{"version":3,"sources":["webpack://./src/styles.module.scss","webpack://./src/features/navbar/components/Sidebar.module.scss"],"names":[],"mappings":"AAYA;EACE,2CAXiB;ACAnB;;ADcA;EACE,iDAAA;ACXF;;ADcA;EACE,yBAAA;ACXF;;ADcA;EACE,qBAAA;ACXF;;ADcA;;;;EAIE,qBAAA;ACXF;;AAnBA;EAEE,uBAAA;EACA,UAAA;EACA,iBAAA;EACA,cAAA;AAqBF;;AAlBA;EACE,aAAA;EACA,aAAA;EAEA,mBAAA;AAoBF;;AAjBA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;AAoBF;;AAjBA;EACE,WAAA;AAoBF;;AAhBA;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,sBAAA;AAmBF;;AAhBA;EACE,eAAA;EACA,iBAAA;EACA,yBAAA;AAmBF","sourcesContent":["$color-primary: #007bff;\n\n$color-background: rgba(239, 238, 238, 0.71);\n$color-light-grey: #dacece;\n$color-grey: #c5c5cb;\n$color-light-black: #3a3939;\n\n$size-large: 40px;\n$size-medium: 24px;\n$size-big-normal: 18px;\n$size-normal: 15px;\n\nbody {\n  background-color: $color-background;\n}\n\n.border_shadow {\n  box-shadow: 0 3px 6px #00000029;\n}\n\n.border {\n  border: 1px solid $color-light-grey;\n}\n\n:global(.mapboxgl-ctrl-geocoder) {\n  z-index: 0!important;\n}\n\n:global(.mapboxgl-ctrl-bottom-left),\n:global(.mapboxgl-ctrl-bottom-righ),\n:global(.mapboxgl-ctrl-top-left),\n:global(.mapboxgl-ctrl-top-right){\n  z-index: 0!important;\n}\n","@import \"src/styles.module\";\n\n.main_container {\n  @extend .border_shadow;\n  background-color: white;\n  width: 15%;\n  min-height: 100vh;\n  flex-shrink: 0;\n}\n\n.top_container {\n  display: flex;\n  padding: 10px;\n  //justify-content: center;\n  align-items: center;\n}\n\n.top_label_container {\n  display: flex;\n  flex-direction: column;\n  margin-left: 20px;\n}\n\n.img {\n  width: 75px\n}\n\n\n.management_label {\n  font-size: 22px;\n  line-height: 1.43;\n  font-weight: 600;\n  color: rgb(50, 50, 77);\n}\n\n.sub_label {\n  font-size: 16px;\n  line-height: 1.33;\n  color: rgb(102, 102, 135);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"border_shadow": `Sidebar_border_shadow__VFclP`,
	"main_container": `Sidebar_main_container__5Envw`,
	"border": `Sidebar_border__347yx`,
	"top_container": `Sidebar_top_container__W6bLn`,
	"top_label_container": `Sidebar_top_label_container__LCAWl`,
	"img": `Sidebar_img__+Ldaj`,
	"management_label": `Sidebar_management_label__5H36t`,
	"sub_label": `Sidebar_sub_label__RwuXx`
};
export default ___CSS_LOADER_EXPORT___;
