// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: rgba(239, 238, 238, 0.71);
}

.UpdatePassword_border_shadow__lwuex, .UpdatePassword_main_container__QaYJj {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.UpdatePassword_border__QHUMZ, .UpdatePassword_main_container__QaYJj {
  border: 1px solid #dacece;
}

.mapboxgl-ctrl-geocoder {
  z-index: 0 !important;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-righ,
.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-right {
  z-index: 0 !important;
}

.UpdatePassword_modal__0Wktc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.UpdatePassword_main_container__QaYJj {
  background-color: white;
  padding: 2% 5%;
  border-radius: 6px;
}

.UpdatePassword_button_container__3D9XQ {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.UpdatePassword_text_container__NoO3h {
  display: flex;
  flex-direction: column;
}

.UpdatePassword_text__zk6bc {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles.module.scss","webpack://./src/components/modal/UpdatePassword.module.scss"],"names":[],"mappings":"AAYA;EACE,2CAXiB;ACAnB;;ADcA;EACE,iDAAA;ACXF;;ADcA;EACE,yBAAA;ACXF;;ADcA;EACE,qBAAA;ACXF;;ADcA;;;;EAIE,qBAAA;ACXF;;AAnBA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AAsBF;;AAnBA;EACE,uBAAA;EACA,cAAA;EAEA,kBAAA;AAqBF;;AAjBA;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;AAoBF;;AAjBA;EACE,aAAA;EACA,sBAAA;AAoBF;;AAjBA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AAoBF","sourcesContent":["$color-primary: #007bff;\n\n$color-background: rgba(239, 238, 238, 0.71);\n$color-light-grey: #dacece;\n$color-grey: #c5c5cb;\n$color-light-black: #3a3939;\n\n$size-large: 40px;\n$size-medium: 24px;\n$size-big-normal: 18px;\n$size-normal: 15px;\n\nbody {\n  background-color: $color-background;\n}\n\n.border_shadow {\n  box-shadow: 0 3px 6px #00000029;\n}\n\n.border {\n  border: 1px solid $color-light-grey;\n}\n\n:global(.mapboxgl-ctrl-geocoder) {\n  z-index: 0!important;\n}\n\n:global(.mapboxgl-ctrl-bottom-left),\n:global(.mapboxgl-ctrl-bottom-righ),\n:global(.mapboxgl-ctrl-top-left),\n:global(.mapboxgl-ctrl-top-right){\n  z-index: 0!important;\n}\n","@import \"src/styles.module\";\n\n.modal {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.main_container {\n  background-color: white;\n  padding: 2% 5%;\n  @extend .border_shadow;\n  border-radius: 6px;\n  @extend .border;\n}\n\n.button_container {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 20px;\n}\n\n.text_container {\n  display: flex;\n  flex-direction: column;\n}\n\n.text {\n  font-size: 24px;\n  font-weight: 600;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"border_shadow": `UpdatePassword_border_shadow__lwuex`,
	"main_container": `UpdatePassword_main_container__QaYJj`,
	"border": `UpdatePassword_border__QHUMZ`,
	"modal": `UpdatePassword_modal__0Wktc`,
	"button_container": `UpdatePassword_button_container__3D9XQ`,
	"text_container": `UpdatePassword_text_container__NoO3h`,
	"text": `UpdatePassword_text__zk6bc`
};
export default ___CSS_LOADER_EXPORT___;
