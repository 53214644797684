import client from "./Client";
import {CreateProjectRequest} from "../features/management/models/CreateProjectRequest";
import {ProjectUpdateRequest} from "../features/management/models/ProjectUpdateRequest";


const PATH = "management/project";

const createProject = (request: CreateProjectRequest) =>
    client.post(PATH, {
        name: request.name,
        activeWindowStart: new Date(request.activeWindowStart).getTime(),
        activeWindowEnd: new Date(request.activeWindowEnd).getTime(),
        isActive: request.isActive,
        adress: request.adress,
        coordinates: request.coordinates
    });

const updateProject = (projectId: number, request: ProjectUpdateRequest) =>
    client.put(PATH + "/" + projectId, request)

const getProjectById = (id: number) =>
    client.get(PATH + "/" + id)
const getAllProject = (fts: string, offset: number, row: number, order: string) =>
    client.get(PATH,
        {
            params: {fts, offset, row, order}
        }
    )

const addClientToProject = (projectId: number, clientIds: number[]) =>
    client.put(PATH + "/" + projectId + "/client", {clientIds})

const addReporterToProject = (projectId: number, reporterId: number) =>
    client.post(PATH + "/" + projectId + "/client/" + reporterId + "/reporter")

const downloadAllImagesByProject = (projectId: number,folderName: string) =>
    client.get(PATH + "/" + projectId + "/images",{ params:{folderName},responseType:'arraybuffer'})

const addErrorOnCoordinates = (projectId:number|undefined,percentage:number)=>
    client.put(PATH + "/" + projectId + "/coordinates",percentage)
export {
    createProject,
    getProjectById,
    getAllProject,
    updateProject,
    addClientToProject,
    addReporterToProject,
    downloadAllImagesByProject,
    addErrorOnCoordinates
}
