import {createContext, Dispatch, SetStateAction} from "react";
import {SnackBarType} from "../models/SnackBarType";

export const SnackBarContext = createContext<ISnackBarContext>({
    snackBarType: "success",
    message: "",
    open: false,
    setSnackBarType: () => null,
    setMessage: () => null,
    setOpen: () => null,
})

interface ISnackBarContext {
    snackBarType: SnackBarType;
    open: boolean;
    message: string;
    setSnackBarType: Dispatch<SetStateAction<SnackBarType>>;
    setMessage: Dispatch<SetStateAction<string>>;
    setOpen: Dispatch<SetStateAction<boolean>>;
}
