import client from "./Client";
import {CLIENT_URL} from "../utils/Constant";
import {CreateClientRequest} from "../features/management/models/CreateClientRequest";
import {ClientUpdateRequest} from "../features/management/models/ClientUpdateRequest";

const PATH = CLIENT_URL
const createClient = (request: CreateClientRequest) =>
    client.post(PATH, {
        username: request.username,
        email: request.email,
        password: request.password,
        activeWindowStart: new Date(request.activeWindowStart).getTime(),
        activeWindowEnd: new Date(request.activeWindowEnd).getTime(),
        active: request.active
    })

const getClientById = (clientId: number) =>
    client.get(PATH + "/" + clientId);

const getAllClient = (fts: string, offset: number, row: number, order: string, activeOnly: boolean) =>
    client.get(PATH, {
        params: {fts, offset, row, order,activeOnly}
    })

const updateClient = (clientId: number, request: ClientUpdateRequest) =>
    client.put(PATH + "/" + clientId, request)

const updateClientPassword = (clientId: number, password: string) =>
    client.patch(PATH, {}, {
        params: {
            id: clientId,
            password: password,
        }
    });

export {
    createClient,
    getClientById,
    getAllClient,
    updateClient,
    updateClientPassword
}
