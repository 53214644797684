import {useContext, useEffect, useState} from "react";
import {AxiosResponse} from "axios";

import TableFormComponent from "../components/table/TableFormComponent";
import i18n from "../i18n";
import {SnackBarContext} from "../features/snackbar/context/SnackBarContext";
import {getAllClient} from "../api/clients";
import {Client} from "../features/management/models/Client";
import CreateClientForm from "../features/management/components/CreateClientForm";
import {PaginatedResponse} from "../features/management/models/PaginatedResponse";
import {ITableColumn} from "../components/table/TableHeaderSort";
import {handleErrorMessage} from "../utils/Common";


export default function ClientManagement() {
    const {setSnackBarType, setOpen, setMessage} = useContext(SnackBarContext);
    const [data, setData] = useState<PaginatedResponse<Client>>(new PaginatedResponse<Client>());
    const [canUpdate, setCanUpdate] = useState<boolean>(false);
    const [fts, setFts] = useState<string>("");
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [sorting,setSorting] = useState<string>("");


    const listAllClient = (fts: string, offset: number, row: number,order:string) => {
        getAllClient(fts, offset, row,order,false).then((response: AxiosResponse<PaginatedResponse<Client>>) => {
            setData(response.data);
        }).catch((error) => {
            setSnackBarType("error")
            setMessage(handleErrorMessage(error));
            setOpen(true);
        })
    }

    useEffect(() => {
        if (canUpdate) {
            listAllClient(fts, page * rowsPerPage, rowsPerPage,sorting);
        }
    }, [canUpdate, fts, page, rowsPerPage,sorting]);

    const handlePageChange = (event: unknown, page: number) =>  {
        setPage(page);
    }

    return (
        <div style={{width:'100%'}}>
            <TableFormComponent title={i18n.t("client.title")}
                                buttonText={i18n.t("client.create")}
                                FormComponent={CreateClientForm}
                                tableColumn={tableColumn}
                                data={data}
                                onShowFormChange={(value: boolean) => setCanUpdate(value)}
                                page={page}
                                handlePageChange={handlePageChange}
                                handleRowChange={setRowsPerPage}
                                orderTable={setSorting}
                                rowsPerPage={rowsPerPage}
                                order={sorting}
                                setFts={setFts}
            />
        </div>
    )
}

const tableColumn: ITableColumn<Client>[] = [
    {
        headerTitle: i18n.t("header.client.username"),
        sortName: "username",
        data: (client: Client) => client.username,
        type: "string",
    },
    {
        headerTitle: i18n.t("header.client.email"),
        sortName: "email",
        data: (client: Client) => client.email,
        type: "string",
    },
    {
        headerTitle: i18n.t("header.client.start_activity"),
        sortName: "activeWindowStart",
        data: (client: Client) => client.activeWindowStart,
        type: "date",
    },
    {
        headerTitle: i18n.t("header.client.end_activity"),
        sortName: "activeWindowEnd",
        data: (client: Client) => client.activeWindowEnd,
        type: "date"
    },
    {
        headerTitle: i18n.t("header.client.active"),
        sortName: "active",
        data: (client: Client) => client.active,
        type: "active"
    },
];
