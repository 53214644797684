import axios from "axios";
import {BASE_URL, LOCAL_STORAGE_TOKEN} from "../utils/Constant";

const client = () => {

    const defaultOptions = {
        baseURL: BASE_URL,
        headers: {
            'Content-Type': 'application/json',
        },
    }

    let instance = axios.create(defaultOptions);
    instance.interceptors.request.use(function (config) {
        const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
        config.headers.Authorization = token ? `Bearer ${token}` : '';
        return config;
    });

    return instance;
}
export default client();
