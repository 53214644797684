// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchBar_search-bar__voO7N {
  display: flex;
  align-items: center;
  width: 40%;
}

.SearchBar_search-input__V-E1Z {
  width: 100%;
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.SearchBar_search-icon__n0n\\+J {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/SearchBar.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,UAAA;AACF;;AAGA;EACE,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;EACD,mBAAA;AAAD;;AAGA;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,2BAAA;AAAF","sourcesContent":[".search-bar {\n  display: flex;\n  align-items: center;\n  width: 40%;\n\n}\n\n.search-input {\n  width: 100%;\n  padding-left: 30px;\n  padding-top: 15px;\n  padding-bottom: 5px;\n margin-bottom: 10px;\n}\n\n.search-icon {\n  position: absolute;\n  top: 50%;\n  right: 10px;\n  transform: translateY(-50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search-bar": `SearchBar_search-bar__voO7N`,
	"search-input": `SearchBar_search-input__V-E1Z`,
	"search-icon": `SearchBar_search-icon__n0n+J`
};
export default ___CSS_LOADER_EXPORT___;
