import {Checkbox, TableCell} from "@mui/material";
import {ChangeEvent, useEffect, useState} from "react";

export default function TabCheckbox<T>({data, selected, onChecked}: TabCheckboxProps<T>) {

    const [checked, setChecked] = useState<boolean>(false);

    useEffect(() => {
        if (selected.includes(data)) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [selected])

    return(
        <TableCell padding={"checkbox"} align="left" sx={{width:"9%"}}>
            <Checkbox
                color="primary"
                checked={checked}
                style={{width:"10%"}}
                onChange={(event, checked) => onChecked(checked, data)}
            />
        </TableCell>
    )
}

interface TabCheckboxProps<T> {
    data: T;
    selected: T[];
    onChecked: (checked: boolean, data: T) => void;
}
