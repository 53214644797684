import {createContext, Dispatch, SetStateAction} from "react";

export const AuthContext = createContext<IAuthContext>({
    logged: false,
    setLogged: () => null,
})

interface IAuthContext {
    logged: boolean;
    setLogged: Dispatch<SetStateAction<boolean>>;
}
