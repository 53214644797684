import React, {useContext, useEffect, useState} from 'react';

import TextInput from "../../../components/form/TextInput";
import styles from "./CreateUser.module.scss";
import i18n from "../../../i18n";
import {AxiosError, AxiosResponse} from "axios";
import {ErrorResponse} from "../../../models/ErrorResponse";
import {User} from "../models/User";
import {SnackBarContext} from "../../snackbar/context/SnackBarContext";
import {Button} from "@mui/material";
import {CreateClientRequest} from "../models/CreateClientRequest";
import {Client} from "../models/Client";
import {createClient, getClientById, updateClient} from "../../../api/clients";
import {ClientUpdateRequest} from "../models/ClientUpdateRequest";
import {IFormDataProps} from "../../../components/table/FormRender";
import UpdatePassword from "../../../components/modal/UpdatePassword";
import PasswordInput from "../../../components/form/PasswordInput";
import {handleErrorMessage} from "../../../utils/Common";

export default function CreateClientForm({data, setShowForm}: IFormDataProps<Client>) {
    const emailRegexPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;


    const [formData, setFormData] = useState<CreateClientRequest>(new CreateClientRequest());
    const {setSnackBarType, setOpen, setMessage} = useContext(SnackBarContext);
    const [dataId, setDataId] = useState<number>(0);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [emailValid, setEmailValid] = useState<boolean>(true);

    useEffect(() => {
        if (data?.id) {
            setDataId(data.id);
            setShowModal(false);
            getClientById(data.id).then((response: AxiosResponse<Client>) => {
                setFormData(
                    {
                        username: response.data?.username ?? "",
                        password: response.data?.password ?? "",
                        email: response.data?.email ?? "",
                        activeWindowStart: response.data?.activeWindowStart
                            ? new Date(response.data?.activeWindowStart).getTime() : new Date().getTime(),
                        activeWindowEnd: response.data?.activeWindowEnd
                            ? new Date(response.data?.activeWindowEnd).getTime() : new Date().getTime(),
                        active: true,
                    }
                )
            }).catch((error: AxiosError<ErrorResponse>) => {
                setSnackBarType("error")
                setMessage(handleErrorMessage(error));
                setOpen(true);
            });
        }
        if (data) {
            validateEmail(data.email ?? "");
        }

    }, [data])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const {name, value, type} = e.target;
        if (name === "email") {
            validateEmail(value);
        }
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "number" ? parseInt(e.target.value, 10) : value,
        }));
    };

    const handleStartDateChange = (date: Date) => {
        setFormData((prevState) => ({
            ...prevState,
            activeWindowStart: date?.getTime(),
        }))
    }

    const handleEndDateChange = (date: Date) => {
        setFormData((prevState) => ({
            ...prevState,
            activeWindowEnd: date?.getTime(),
        }))
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        dataId === 0 ? onCreate() : onUpdate();
    };

    const onCreate = () => {
        createClient(formData).then(
            (response: AxiosResponse<User>) => {
                setSnackBarType("success")
                setMessage(i18n.t("user.success"));
                setOpen(true);
                setFormData(new CreateClientRequest());
                setShowForm(false);
            }
        ).catch((error: AxiosError<ErrorResponse>) => {
            setSnackBarType("error")
            setMessage(handleErrorMessage(error));
            setOpen(true);
        })
    }

    const onUpdate = () => {
        let request: ClientUpdateRequest = {
            username: formData.username,
            password: formData.password,
            email: formData.email,
            activeWindowEnd: formData.activeWindowEnd,
            activeWindowStart: formData.activeWindowStart,
            isActive: formData.active,
        }
        updateClient(dataId, request).then(
            (response: AxiosResponse<User>) => {
                setSnackBarType("success")
                setMessage(i18n.t("user.success_update"));
                setOpen(true);
                setFormData(new CreateClientRequest());
                setShowForm(false);
            }
        ).catch((error: AxiosError<ErrorResponse>) => {
            setSnackBarType("error")
            setMessage(handleErrorMessage(error));
            setOpen(true);
        })
    }

    const validateEmail = (email: string) => {
        return setEmailValid(emailRegexPattern.test(email));
    };

    return (
        <div className={styles.main_container}>
            <form className="create_user_form" onSubmit={handleSubmit}>
                <div className={styles.form_group}>
                    <TextInput
                        label={i18n.t("client.username")}
                        type="text"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.form_group}>
                    {dataId ?
                        <Button variant={"contained"}
                                onClick={() => setShowModal(true)}>{i18n.t("client.change_pwd")}</Button>
                        :
                        <PasswordInput
                            label={i18n.t("client.password")}
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            limit={4}
                            required
                        />
                    }
                </div>
                <div className={styles.form_group}>
                    <TextInput
                        label={i18n.t("client.email")}
                        type="text"
                        name="email"
                        className={emailValid ? "" : styles.error}
                        value={formData.email}
                        onChange={handleChange}
                        error={!emailValid}
                        required
                    />
                </div>
                <div className={styles.date_container}>
                    <div className={styles.form_group}>
                        <TextInput
                            label={i18n.t("client.start_activity")}
                            type={"date"}
                            name="activeWindowStart"
                            value={formData.activeWindowStart}
                            onChange={handleStartDateChange}
                            required={true}
                        />
                    </div>
                    <label className={styles.date_separate}>&nbsp;-&nbsp;</label>
                    <div className={styles.form_group}>
                        <TextInput type={"date"}
                                   label={i18n.t("client.end_activity")}
                                   name={"activeWindowEnd"}
                                   value={formData.activeWindowEnd}
                                   onChange={handleEndDateChange}
                                   required={true}/>
                    </div>
                </div>
                <div className={styles.button_container}>
                    <Button variant={"contained"}
                            className={styles.button}
                            disabled={!emailValid || !formData.email || !formData.username}
                            type="submit">{i18n.t("user.save")}</Button>
                </div>

            </form>
            <UpdatePassword open={showModal}
                            id={dataId}
                            onClose={() => setShowModal(false)}/>
        </div>

    );
}

