import {Button, Modal} from "@mui/material";
import styles from "./DeleteModal.module.scss";
import i18n from "../../i18n";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export default function DeleteModal<T>({open, handleClose, selectedNumber, handleDelete}: DeleteModalProps<T>) {


    return (
        <Modal open={open} onClose={handleClose} className={styles.modal}>
            <div className={styles.main_container}>
                <label className={styles.main_text}>{i18n.t("common.delete_confirm")}</label>
                <div className={styles.line}></div>
                <label className={styles.sub_text}>{i18n.t("common.delete_sure") +selectedNumber + i18n.t("common.selected")}</label>

                <div className={styles.button_container}>
                    <Button variant={"outlined"}
                            color={"error"}
                            onClick={handleClose}
                            startIcon={<CloseIcon/>}
                            className={styles.button}
                    >
                        {i18n.t("common.cancel")}
                    </Button>

                    <Button variant={"outlined"}
                            onClick={handleDelete}
                            color={"success"}
                            startIcon={<CheckIcon/>}
                            className={styles.button}
                    >
                        {i18n.t("common.confirm")}
                    </Button>

                </div>

            </div>
        </Modal>
    )
}

interface DeleteModalProps<T> {
    open: boolean;
    handleClose: () => void;
    handleDelete: () => void;
    selectedNumber: number;
}
