import styles from "./TableGroupComponent.module.scss";
import {PaginatedResponse} from "../../../features/management/models/PaginatedResponse";
import TableHeaderSort, {ITableColumn} from "../TableHeaderSort";
import {ChangeEvent, Dispatch, SetStateAction} from "react";
import {
    Checkbox,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import TablePaginationCustom from "../TablePaginationCustom";
import {Simulate} from "react-dom/test-utils";
import keyUp = Simulate.keyUp;
import TableGroup from "./TableGroup";
import SearchBar from "../../SearchBar/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";

export default function TableGroupComponent<T>({
                                                   title,
                                                   data,
                                                   tableColumn,
                                                   handlePageChange,
                                                   handleRowChange,
                                                   page,
                                                   rowsPerPage,
                                                   orderTable,
                                                   order,
                                                   searchable = true,
                                                   editable = true,
                                                   checkable = false,
                                                   deletable = false,
                                                   setFts,
                                                   selected = [],
                                                   setSelected,
                                                   onDeleteClick
                                               }: TableGroupComponentProps<T>) {
    const renderGroup = () => {
        if (data?.data != null) {
            let mapData = data?.data[0];
           return Object.keys(mapData).map((key) =>
               <TableGroup name={key}
                           key={key}
                           tableColumn={tableColumn}
                           selected={selected}
                           checkable={checkable}
                           handleSelect={handleSelect}
                           // @ts-ignore
                           values={mapData[key]}/>
           )
        }
    }

    const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
        if (setSelected) {
            if (event?.target?.checked) {
                const mapData = data?.data[0];
                const newSelected: T[] = [];
                Object.values(mapData).forEach((value) => {
                    value.map((v: T) => newSelected.push(v));
                })
                console.log(newSelected, "rfe")
                setSelected(newSelected);
            } else {
                setSelected([]);
            }
        }
    }

    const handleSelect = (checked: boolean, data: T) => {
        if (setSelected) {
            if (checked) {
                setSelected(prevState => [...prevState, data]);
            } else {
                setSelected(prevState => {
                    return  prevState.filter((value) => value != data);
                });
            }
        }
    }


    return(
        <div className={styles.main_container}>
            <div className={styles.top_container}>
                <label className={styles.title}>{title}</label>
            </div>
            <TableContainer component={Paper}>
                <div className={styles.table_top_container}>
                    {searchable && <SearchBar onSearch={setFts}/>}
                    {deletable && selected?.length > 0 &&
                        <IconButton color={"error"} onClick={onDeleteClick}>
                            <DeleteIcon sx={{width: 40, height: 40}} />
                        </IconButton>
                    }
                </div>
                <Table sx={{minWidth: 650}}
                       size={"small"}>
                    <TableHead>
                        <TableRow>
                            {checkable &&
                                <TableCell padding={"checkbox"} key={"checked"}>
                                    <Checkbox
                                        color="primary"
                                        checked={selected?.length > 0}
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                            }
                            {tableColumn.map((column) =>
                                <TableHeaderSort column={column}
                                                 order={order}
                                                 key={column.headerTitle}
                                                 orderTable={orderTable}/>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderGroup()}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePaginationCustom data={data}
                                   page={page}
                                   rowsPerPage={rowsPerPage}
                                   handlePageChange={handlePageChange}
                                   handleRowChange={handleRowChange}/>
        </div>
    )
}

interface TableGroupComponentProps<T> {
    title: string;
    data: PaginatedResponse<Map<string,T[]>>;
    tableColumn: ITableColumn<T>[];
    page: number;
    handlePageChange: (event: unknown, page: number) => void;
    rowsPerPage: number;
    handleRowChange: Dispatch<SetStateAction<number>>;
    orderTable: (value:string) => void;
    order: string;
    setFts: (value: string) => void;
    searchable?: boolean;
    checkable?: boolean;
    deletable?: boolean;
    editable?: boolean;
    selected?: T[];
    setSelected?: Dispatch<SetStateAction<T[]>>;
    onDeleteClick?: () => void;
}
