import client from "./Client";
import {ReportPaginationRequest} from "../features/report/models/ReportPaginationRequest";
import {ReportImages} from "../features/management/models/report/ReportImages";
import {Report} from "../features/management/models/report/Report";

const PATH = "management/report"

const getReportGroupByProject = (request: ReportPaginationRequest) =>
    client.get(PATH + "/projects" , {
        params: request
    })

const getReportImagesByReportId = (reportId: number) =>
    client.get(PATH + "/report/" + reportId + "/images")

const searchReports = (request: ReportPaginationRequest) =>
    client.get(PATH, {
        params: request
    })

const validateReport = (reportId: number) =>
    client.patch(PATH + "/" + reportId + "/accepted")
const rejectReport = (reportId: number) =>
    client.patch(PATH + "/" + reportId + "/rejected")

const deleteReport = (reportId: number) =>
    client.delete(PATH + "/" + reportId)

export {
    getReportGroupByProject,
    searchReports,
    validateReport,
    rejectReport,
    getReportImagesByReportId,
    deleteReport
}
