// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: rgba(239, 238, 238, 0.71);
}

.DeleteModal_border_shadow__in8Wc {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.DeleteModal_border__wiqnz {
  border: 1px solid #dacece;
}

.mapboxgl-ctrl-geocoder {
  z-index: 0 !important;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-righ,
.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-right {
  z-index: 0 !important;
}

.DeleteModal_modal__FRmer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DeleteModal_main_container__4rlIy {
  display: flex;
  flex-direction: column;
  background: white;
  width: 50vh;
  border-radius: 6px;
}

.DeleteModal_main_text__pA6c2 {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
  padding: 10px;
}

.DeleteModal_line__G9G0n {
  border-bottom: solid #3a3939 0.5px;
  margin-bottom: 10px;
}

.DeleteModal_sub_text__ylMXw {
  font-size: 18px;
  padding: 0 10px;
}

.DeleteModal_button_container__SRo4x {
  margin-top: 20px;
  display: flex;
  align-self: end;
  justify-content: space-between;
  padding: 10px;
}

.DeleteModal_button__w\\+SF8 {
  margin-right: 2%;
}`, "",{"version":3,"sources":["webpack://./src/styles.module.scss","webpack://./src/components/modal/DeleteModal.module.scss"],"names":[],"mappings":"AAYA;EACE,2CAXiB;ACAnB;;ADcA;EACE,iDAAA;ACXF;;ADcA;EACE,yBAAA;ACXF;;ADcA;EACE,qBAAA;ACXF;;ADcA;;;;EAIE,qBAAA;ACXF;;AAnBA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AAsBF;;AApBA;EACE,aAAA;EACA,sBAAA;EAEA,iBAAA;EACA,WAAA;EAEA,kBAAA;AAqBF;;AAlBA;EACE,eAAA;EACA,iBAAA;EACA,cDpBc;ECqBd,aAAA;AAqBF;;AAlBA;EACE,kCAAA;EACA,mBAAA;AAqBF;;AAlBA;EACE,eAAA;EACA,eAAA;AAqBF;;AAlBA;EACE,gBAAA;EACA,aAAA;EACA,eAAA;EACA,8BAAA;EACA,aAAA;AAqBF;;AAnBA;EACE,gBAAA;AAsBF","sourcesContent":["$color-primary: #007bff;\n\n$color-background: rgba(239, 238, 238, 0.71);\n$color-light-grey: #dacece;\n$color-grey: #c5c5cb;\n$color-light-black: #3a3939;\n\n$size-large: 40px;\n$size-medium: 24px;\n$size-big-normal: 18px;\n$size-normal: 15px;\n\nbody {\n  background-color: $color-background;\n}\n\n.border_shadow {\n  box-shadow: 0 3px 6px #00000029;\n}\n\n.border {\n  border: 1px solid $color-light-grey;\n}\n\n:global(.mapboxgl-ctrl-geocoder) {\n  z-index: 0!important;\n}\n\n:global(.mapboxgl-ctrl-bottom-left),\n:global(.mapboxgl-ctrl-bottom-righ),\n:global(.mapboxgl-ctrl-top-left),\n:global(.mapboxgl-ctrl-top-right){\n  z-index: 0!important;\n}\n","@import \"src/styles.module\";\n\n.modal {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.main_container {\n  display: flex;\n  flex-direction: column;\n\n  background: white;\n  width: 50vh;\n  //height: 80%;\n  border-radius: 6px;\n}\n\n.main_text {\n  font-size: 24px;\n  font-weight: bold;\n  color: $color-primary;\n  padding: 10px;\n}\n\n.line {\n  border-bottom: solid $color-light-black 0.5px;\n  margin-bottom: 10px;\n}\n\n.sub_text {\n  font-size: 18px;\n  padding: 0 10px;\n}\n\n.button_container {\n  margin-top: 20px;\n  display: flex;\n  align-self: end;\n  justify-content: space-between;\n  padding: 10px;\n}\n.button{\n  margin-right: 2%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"border_shadow": `DeleteModal_border_shadow__in8Wc`,
	"border": `DeleteModal_border__wiqnz`,
	"modal": `DeleteModal_modal__FRmer`,
	"main_container": `DeleteModal_main_container__4rlIy`,
	"main_text": `DeleteModal_main_text__pA6c2`,
	"line": `DeleteModal_line__G9G0n`,
	"sub_text": `DeleteModal_sub_text__ylMXw`,
	"button_container": `DeleteModal_button_container__SRo4x`,
	"button": `DeleteModal_button__w+SF8`
};
export default ___CSS_LOADER_EXPORT___;
