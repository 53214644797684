import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {useState} from "react";
import TableRowRender from "../TableRowRender";
import {ITableColumn} from "../TableHeaderSort";
import {Button, Collapse, Table, TableBody, TableCell, TableRow} from "@mui/material";
import TabCheckbox from "../TabCheckbox";

export default function TableGroup<T>({name, values, tableColumn, checkable = false, selected, handleSelect}: TableGroupProps<T>) {
    const [showReport, setShowReport] = useState<boolean>(true);
    const toggle = () => {
        setShowReport(!showReport);
    }

    const render = () => {
        if(showReport){
            return values.map((data, index) =>
                <TableRow key={index}>
                    {checkable && handleSelect && selected &&
                        <TabCheckbox key={index + "checked"}
                                     data={data}
                                     onChecked={handleSelect}
                                     selected={selected}
                        />
                    }
                    {tableColumn.map((row, i) =>
                        <TableRowRender data={showReport ? row.data(data) : null}
                                        type={row.type}
                            // @ts-ignore
                                        onClick={() => row.onClick(data)}
                                        key={index + i}/>)}
                </TableRow>
            )
        }

    }

    return(

        <>
            <TableRow onClick={toggle} sx={{width:250,textAlign:'left'}}>
                <Button
                    size="small"
                    onClick={toggle}
                    style={{width:250,justifyContent:'flex-start'}}
                >
                    {showReport ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    {name}
                </Button>
            </TableRow>
                {render()}

        </>

    )
}

interface TableGroupProps<T> {
    name: string;
    values: T[];
    tableColumn: ITableColumn<T>[];
    checkable?: boolean;
    selected?: T[];
    handleSelect?: (checked: boolean, data: T) => void;
}
