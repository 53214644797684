import styles from "./TextInput.module.scss"
import {IconButton, Input, InputAdornment} from "@mui/material";
import {useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {kMaxLength} from "buffer";
export default function PasswordInput({label, name, required, value, onChange, className, onkeyup,limit}: PasswordInputProps) {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return(
        <div className={styles.main_container}>
            <label className={styles.text_style}>{label}</label>
            <Input
                type={showPassword ? "type" : "password"}
                className={[styles.input, className].join(" ")}
                required={required}
                inputProps={{maxLength:limit??10000}}
                value={value}
                name={name}
                onChange={(e) =>  onChange(e)}
                onKeyUp={(e) => onkeyup ? onkeyup(e) : null}
                endAdornment={
                    <InputAdornment position={"end"}>
                        <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </div>
    )
}

interface PasswordInputProps {
    label: string;
    name?: string;
    required?: boolean;
    value: string|number;
    onChange: (value: any) => void;
    className?: string;
    onkeyup?: (value: any) => void;
    limit?:number;
}
