import React, {useEffect, useState} from 'react';
import styles from './SearchBar.module.scss';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import i18n from "i18next";

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {

        const timeoutId = setTimeout(() => {
            onSearch(searchTerm);
        }, 500);

        return () => clearTimeout(timeoutId);

    }, [searchTerm])

    // const handleChange = debounce((event: React.ChangeEvent<HTMLInputElement>) => onSearch(event.target.value))
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    return (
        <div className={styles['search-bar']}>
            <TextField
                type="text"
                value={searchTerm}
                onChange={handleChange}
                placeholder={i18n.t("common.search")}
                variant="standard"
                size="small"
                fullWidth
                InputProps={{
                    startAdornment: (
                        <SearchIcon color="action" />
                    ),
                    className: styles['search-input'],
                }}
            />
        </div>
    );
};

interface SearchBarProps {
    onSearch: (searchTerm: string) => void;
}
export default SearchBar;
